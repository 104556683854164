<template>
  <div class="mobileCurtainParam">
    <mHead :name="'窗帘电机'" :add="'/mobile/ProCurtain'" :adds="'/mobile/ProCurtainParam'" :color="false" />
    <div class="param">
      <div class="title">相关参数</div>
      <div class="text">
        <div class="left">产品型号:</div>
        <div class="right">M300AI</div>
      </div>
      <div class="text">
        <div class="left">产品尺寸:</div>
        <div class="right">86×86×13mm</div>
      </div>
      <div class="text">
        <div class="left">额定功率:</div>
        <div class="right">5-36</div>
      </div>
      <div class="text">
        <div class="left">供电方式:</div>
        <div class="right">直流</div>
      </div>
<div class="text">
        <div class="left">控制接口:</div>
        <div class="right">2×3Pin-D2.5mm金针</div>
      </div>
      <div class="text">
        <div class="left">工作频段:</div>
        <div class="right">433.0MHz~453.0MHz</div>
      </div>
      <div class="text">
        <div class="left">工作温度:</div>
        <div class="right">负20°C-65°C</div>
      </div>
      <div class="text">
        <div class="left">工作湿度:</div>
        <div class="right">0%-95%RH，无冷凝</div>
      </div>
      <div class="text">
        <div class="left">静音指数:</div>
        <div class="right">＜22DB</div>
      </div>

      <div class="text">
        <div class="left">驱动方式:</div>
        <div class="right">智能音箱、开关面板、手动操作</div>
      </div>
       <div class="text">
        <div class="left">通信协议:</div>
        <div class="right">自定义LSSN2.0</div>
      </div>
       <div class="text">
        <div class="left">无线发射功率:</div>
        <div class="right">＜10dBm</div>
      </div>
    </div>
    <div class="paramDe">
      <div
        class="txt"
      >窗帘电机是电动窗帘/窗纱的驱动设备，需配合专用轨道使用，可实现对窗帘/窗纱开闭的无线控制。窗帘电机本身还具有手动拉动开闭的功能，以满足用户的不同使用习惯。专用轨道的长度需根据窗帘/窗纱的尺寸定制，可做成直线形、弧形和异形结构，从而适应不同的户型设计。</div>
      <div class="data">
        <img src="../../assets/mobile/dataImg.png" class="dataImg" />
      </div>
    </div>
  </div>
</template>

<script>
import mHead from "../../components/MobileHead.vue";
export default {
  components: {
    mHead
  },
  data() {
    return {};
  }
};
</script>
<style scoped lang="less">
.mobileCurtainParam {
  background-color: #fff;
  .param {
    margin: 0 0.3rem;
    padding-bottom: 0.29rem;
    border-bottom: 0.01rem solid #333;
    .title {
      margin-top: 0.8rem;
      margin-bottom: 0.59rem;
      font-size: 0.48rem;
      line-height: 0.48rem;
      font-weight: bold;
      color: #ff9000;
    }
    .text {
      margin-top: 0.26rem;
      display: flex;
      font-size: 0.24rem;
      line-height: 0.24rem;
      font-weight: 400;
      color: #333333;
      &:first-of-type {
        margin-top: 30px;
      }
      .left {
        width: 1.56rem;
        text-align: right;
      }
      .right {
        margin-left: 0.59rem;
      }
    }
  }
  .paramDe {
    margin-top: 0.29rem;
    padding: 0 0.3rem;
    .txt {
      font-size: 0.24rem;
      line-height: 0.4rem;
      font-weight: 400;
      color: #333333;
      text-align: justify;
    }
    .data {
      width: 6.45rem;
      margin-top: 0.39rem;
      padding-bottom: 0.8rem;
      display: flex;

      .dataImg {
        width: 100%;
        height: 0.18rem;
      }
    }
  }
}
</style>
